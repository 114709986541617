import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SharedModule } from './core/shared.module';
import { LoginComponent } from './views/login/login.component';

import { AuthHttpInterceptor, AuthModule, AuthConfigService } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from '../environments/environment';
import { PageRibbonComponent } from './core/layout/ribbons/page-ribbon.component';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallbackComponent } from './views/auth/callback/callback.component';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppVersionInterceptor } from './interceptors/app-version.interceptor';
import { CustomMissingTranslationHandler } from './core/handlers/missing-translation.handler';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ErrorComponent } from './views/errors/error/error.component';
import { effects } from './store';
import { SentryModule } from './core/sentry/sentry.module';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';
import { ActivityModule } from './core/activity/activity.module';
import { MainLayoutComponent } from './core/layout/main-layout/main-layout.component';
import { MainContentSidenavComponent } from './views/main-content-sidenav/main-content-sidenav.component';
import { TitleStrategy } from '@angular/router';
import { TemplatePageTitleStrategy } from './strategies/title-strategy';
import { UserWithoutOrgComponent } from './views/auth/user-without-org/user-without-org.component';
import { APP_CONFIG } from "./core/app.constants";
import { ApolloClientOptions, ApolloLink, InMemoryCache, } from "@apollo/client/core";
import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";
import { AppConfig } from "./core/config/models/config";
import { getSentryConfig, SENTRY_CONFIG } from "./core/sentry/sentry.config";
import { getAuthConfig } from "./core/auth/auth.config";
import { captureException } from '@sentry/angular';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function apolloFactory(appConfig: AppConfig, httpLink: HttpLink): ApolloClientOptions<any> {
  const apolloErrorLink = onError((res: ErrorResponse) => {
    const {graphQLErrors, networkError} = res;
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, extensions}) =>
        console.error(`[GraphQL error]: Message: ${message}`, extensions)
      );
      captureException(graphQLErrors);
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`, networkError);
      captureException(networkError);
    }
  });

  const apolloHttpLink = httpLink.create({
    uri: `${appConfig.resourceServer.runwayUrl}graphql`,
    includeExtensions: true,
    extractFiles: (body) => extractFiles(body, isExtractableFile), // We need this for file uploads
  });

  return {
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: ApolloLink.from([apolloErrorLink, apolloHttpLink]),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainLayoutComponent,
    PageRibbonComponent,
    CallbackComponent,
    MainContentSidenavComponent,
    ErrorComponent,
    UserWithoutOrgComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler}
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    AuthModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    !(environment.production) ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(effects),
    ServiceWorkerModule.register('service-worker.js', {enabled: environment.production}),
    SentryModule,
    ActivityModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: SENTRY_CONFIG,
      useFactory: getSentryConfig,
      deps: [APP_CONFIG],
    },
    {
      provide: AuthConfigService,
      useFactory: getAuthConfig,
      deps: [APP_CONFIG]
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloFactory,
      deps: [APP_CONFIG, HttpLink]
    },
    {provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptor, multi: true},
    {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
  constructor() {
  }
}
